<template>
    <div class="top-back">
        <div class="back-operate">
            <van-image
                width="24"
                height="24"
                :src="require('@/assets/images/back.png')"
                @click="onClickLeft"
                class="van-image"
            />
            <span>任务详情</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        linkUrl: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            
        }
    },
    methods: {
        onClickLeft () {
            if (this.$route.query && this.$route.query.fromRoute) {
                this.$router.push(this.$route.query.fromRoute)
            } else {
                if (this.linkUrl) {
                    this.$router.push(this.linkUrl)
                } else {
                    this.$router.go(-1)
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.top-back {
  .back-operate {
        padding-top: 14px;
        height: 24px;
        line-height: 24px;
        padding-left: 10px;
        text-align: center;
        padding-bottom: 4px;
        .van-image {
            position: absolute;
            left: 10px;
        }
        span {
            color: #fff;
            font-size: 17px;
            vertical-align: top;
        }
    }
}
</style>
