<template>
    <div class="surplus">
        <slot name="tipval"></slot>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String
        }
    },
    data () {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.surplus {
    color: #37B8AD;
    font-size: 0.24rem;
    display: inline-block;
    border: 1px solid #37B8AD;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 4px;
    width: auto;
    padding: 0 3px;
    min-width: 60px;
}
</style>
