<template>
  <div class="follow">
    <TopBack :linkUrl="'/mpRenewalTask'" />
    <div class="follow-scroll" id="followScroll">
        <div style="padding: 12px;">
            <div class="car-info">
                <div class="car-time">
                    <span class="plat-no">{{ taskDetail.plateNo }}</span>
                    <TagTip style="position: relative; top: 4px;">
                        <template #tipval>
                            <span>{{ taskDetail.policyMaturityDay }}天后到期</span>
                        </template>
                    </TagTip>
                </div>
                <div class="name-time">
                    <span>车主姓名：{{ taskDetail.ownerName }}</span>
                    <span>到期时间：{{ dayjs(taskDetail.policyEndTime).format('YYYY-MM-DD') }}</span>
                </div>
            </div>
        </div>
        <div style="padding: 12px; padding-top: 0;">
            <div class="tab-con">
                <van-tabs v-model="activeName" @click="tabClick" color="#23C5B8">
                    <van-tab id="tabOne" style="overflow: auto;" name="1" title="任务详情">
                        <div class="task-detail">
                            <div class="car-people">
                                <p>
                                    <span class="title">车主电话</span>
                                    <span class="title-con">{{ taskDetail.ownerPhoneNo }}</span>
                                </p>
                                <p>
                                    <span class="title">车架号</span>
                                    <span class="title-con">{{ taskDetail.frameNo }}</span>
                                </p>
                                <p>
                                    <span class="title">初登日期</span>
                                    <span class="title-con">{{ taskDetail.registerDate && dayjs(taskDetail.registerDate).format('YYYY-MM-DD') }}</span>
                                </p>
                                <p>
                                    <span class="title">品牌车型</span>
                                    <span class="title-con">{{ taskDetail.brandModel }}</span>
                                </p>
                                <p>
                                    <span class="title">发动机号</span>
                                    <span class="title-con">{{ taskDetail.engineNo }}</span>
                                </p>
                            </div>
                            <div class="car-people" style="margin-top: 10px;">
                                <p>
                                    <span class="title">联系人名称</span>
                                    <span class="title-con">{{ taskDetail.contactName }}</span>
                                </p>
                                <p>
                                    <span class="title">联系人电话</span>
                                    <span class="title-con">{{ taskDetail.contactPhoneNo }}</span>
                                </p>
                                <p>
                                    <span class="title">证件号码</span>
                                    <span class="title-con">{{ taskDetail.ownerCertificateNo }}</span>
                                </p>
                            </div>
                            <div class="car-people" style="margin-top: 30px; border: none">
                                <p style="line-height: unset;">
                                    <span class="title" style="vertical-align: top;">交强险投保公司</span>
                                    <span class="title-con" style="display: inline-block; vertical-align: top; width: calc(100% - 100px);">{{ taskDetail.compInsuredOrgName }}</span>
                                </p>
                                <p style="line-height: unset;">
                                    <span class="title" style="vertical-align: top;">商业险投保公司</span>
                                    <span class="title-con" style="display: inline-block; vertical-align: top; width: calc(100% - 100px);">{{ taskDetail.commInsuredOrgName }}</span>
                                </p>
                                <p style="line-height: unset;">
                                    <span class="title" style="vertical-align: top;">交强险到期时间</span>
                                    <span class="title-con" style="vertical-align: top; position: relative; top: 3px;">{{ dayjs(taskDetail.compEndTime).format('YYYY-MM-DD') }}</span>
                                </p>
                                <p style="line-height: unset;">
                                    <span class="title" style="vertical-align: top;">商业险到期时间</span>
                                    <span class="title-con" style="vertical-align: top; position: relative; top: 3px;">{{ dayjs(taskDetail.commEndTime).format('YYYY-MM-DD') }}</span>
                                </p>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab id="tabTwo" style="overflow: auto;" name="2" title="跟进记录">
                        <div class="follow-record">
                            <van-steps direction="vertical" v-if="followRecord.length > 0">
                                <van-step v-for="(item, ind) in followRecord" :key="ind">
                                    <div class="follow-content">
                                        <p class="ordinary-p">
                                            <span class="title">跟进时间：</span>
                                            <span>{{ dayjs(item.trackTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        </p>
                                        <p>
                                            <span class="title">跟进专员：</span>
                                            <span>{{ item.consultantName }}</span>
                                            <TagTip style="background: #37B8AD; color: #fff; position: absolute; right: 24px;">
                                                <template #tipval>
                                                    <span>{{ dictFilter('TRACK_STATUS', item.trackStatus) }}</span>
                                                </template>
                                            </TagTip>
                                        </p>
                                        <p style="line-height: unset;">
                                            <span class="title">跟进记录：</span>
                                            <span class="title-con" style="display: inline-block; vertical-align: top; width: calc(100% - 86px); word-break: break-all;">
                                                {{ item.trackDescription }}
                                            </span>
                                        </p>
                                        <p>
                                            <span class="title">下次跟进日期：</span>{{ item.nextTrackTime && dayjs(item.nextTrackTime).format('YYYY-MM-DD') }}
                                        </p>
                                    </div>
                                </van-step>
                            </van-steps>
                            <p v-else style="font-size: 13px; text-align: center; margin-top: 30%;">
                                还没有跟进记录，快去联系客户吧！
                            </p>
                        </div>
                    </van-tab>
                    <van-tab id="tabThree" style="overflow: auto;" name="3" title="报价记录">
                        <div class="price-record">
                            <van-collapse v-model="priceName">
                                <van-collapse-item v-for="(item, ind) in priceRecord" :key="ind" :name="ind">
                                    <template #title>
                                        <div class="collapse-head">
                                            <span style="font-size: 15px; color: #232323; margin-right: 10px;">{{ item.basis.insuredOrgName }}</span>
                                            <span v-if="!priceName.includes(ind)">
                                                <span style="color: #979797;">&nbsp;|&nbsp;</span>
                                                <span class="title-con"><span style="font-size: 11px;">￥</span>{{ item.total }}元</span>
                                            </span>
                                        </div>
                                    </template>
                                    <div class="content">
                                        <p style="border-bottom: 1px dashed #37B8AD; width: 104%; padding-bottom: 14px;">
                                            <span class="title">报价时间：</span>
                                            <span class="title-con">{{ dayjs(item.basis.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                        </p>
                                        <p style="margin-top: 10px;">
                                            <span class="title">车船税：</span>
                                            <span class="title-con">{{ item.compulsory.vehicleTaxPremium }}元</span>
                                        </p>
                                        <p style="margin-top: 10px;">
                                            <span class="title">交强险：</span>
                                            <span class="title-con">{{ item.compulsory.actualPremium }}元</span>
                                        </p>
                                        <p>
                                            {{ dayjs(item.compulsory.startTime).format('YYYY-MM-DD') }}至{{ dayjs(item.compulsory.endTime).format('YYYY-MM-DD') }}
                                        </p>
                                        <p style="margin-top: 10px;">
                                            <span class="title">商业险：</span>
                                            <span class="title-con">{{ item.commercial.actualPremium }}元</span>
                                        </p>
                                        <p style="border-bottom: 1px dashed #37B8AD; width: 104%; padding-bottom: 14px;">
                                            {{ dayjs(item.commercial.startTime).format('YYYY-MM-DD') }}至{{ dayjs(item.commercial.endTime).format('YYYY-MM-DD') }}
                                        </p>
                                        <p style="font-size: 12px; font-weight: bold; color: #000; margin-top: 10px; padding-bottom: 10px;">商业险险种明显</p>
                                    </div>
                                    <table cellspacing="0" style="width:100%;" class="table">
                                        <tr style="background: #F6F7F7; height: 34px; padding-left: 9px;">
                                            <th class="th" style="text-align: left; text-indent: 9px;">险种</th>
                                            <th class="th" style="text-align: right;">保额</th>
                                            <th class="th" style="text-align: right; padding-right: 9px;">保费（元）</th>
                                        </tr>
                                        <tr v-for="(tItem, tInd) in item.commercial.riskList" :key="tInd" style="padding-left: 9px;" class="list-tr">
                                            <td style="text-indent: 9px; width: 50%;">{{ tItem.riskName }}</td>
                                            <td style="text-align: right; width: 25%;">
                                                <span v-if="riskTable[tItem.riskCode] === '次'">{{ tItem.quantity }}次</span>
                                                <span v-if="!riskTable[tItem.riskCode]">{{ tItem.amount | amountFilter }}</span>
                                            </td>
                                            <td style="text-align: right; padding-right: 9px; width: 25%;">{{ tItem.actualPremium }}</td>
                                        </tr>
                                    </table>
                                    <div class="total-price">
                                        <span class="title">总计：</span>
                                        <span class="title-con"><span style="font-size: 11px;">￥</span>{{ item.total }}元</span>
                                    </div>
                                </van-collapse-item>
                            </van-collapse>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <div class="bottom-btn">
            <van-button round block type="info" color="#15AEA2" class="follow-btn" @click="follow">跟进</van-button>
        </div>
    </div>
    <FollowForm ref="FollowForm" :id="id" @updateList="updateList" />
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { request } from "@/api/service";
import FollowForm from './module/followForm.vue'
import TopBack from '@/components/TopBack.vue'
import TagTip from '@/components/TagTip.vue'
import store from '@/store'
import { isArray } from "lodash";
import { Dialog } from "vant";
export default {
	name:'Follow',
	components: { FollowForm, TopBack, TagTip },
    filters: {
        amountFilter (value) {
            if (value) {
                return parseFloat(value).toFixed(2)
            }
            return '0.00'
        }
    },
	data() {
		return {
            activeName: '',
            activeValue: '1',
            priceName: [0],
            id: '',
            taskDetail: {},
            followRecord: [],
            priceRecord: [],
            riskTable: {
                'COMM0A01': '',
                'COMM0B01': '',
                'COMM0C01': '',
                'COMM0D01': '',
                'COMM0C02': '',
                'COMM0D02': '',
                'COMM0B02': '',
                'COMM0C03': '',
                'COMM0D03': '',
                'COMM0B03': '',
                'COMM0C04': '',
                'COMM0D04': '',
                'COMM0B04': '',
                'COMM0A05': '',
                'COMM0A02': '',
                'COMM0A03': '',
                'COMM0A04': '',
                'COMM0F01': '次',
                'COMM0G01': '次',
                'COMM0H01': '次',
                'COMM0J01': '次',
                'COMM0K01': '',
                'COMM0B05': '',
                'COMM0L01': '',
                'COMM0M01': '',
                'OTHER': ''
            }
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"]),
        ...mapState("user", ["currentMenus"])
    },
	created(){
        this.$nextTick(() => {
            if (this.currentMenus.length > 0 && !this.currentMenus.includes('/mpRenewalTask')) {
                // authorize("#"+ to.fullPath)
                Dialog({ message: '当前用户暂时没有该页面权限' });
                this.$router.push('/mpArriveVehicleRecord')
                return false
            }
        })
        this.id = this.$route.query.id
        store.dispatch('dict/loadDict')
        this.getDetail(this.id)
	},
	mounted () {
        tabOne.style.height = (document.documentElement.clientHeight - 265) + 'px'
        tabTwo.style.height = (document.documentElement.clientHeight - 265) + 'px'
        tabThree.style.height = (document.documentElement.clientHeight - 280) + 'px'
    },
	methods:{
		dayjs,
        search () {
            this.$refs.HYList.pageForm.page = 1
            this.$refs.HYList.onLoadSource()
        },
        getDetail (id) {
            request({
                url: `/afis-renewal/renewal/renewalTask/wxInfo/${id}`,
                method: "get",
                data: {
            }
            }).then(res => {
                this.taskDetail = res
                request({
                    url: `/afis-auto-web/auto/policy/queryRecentSuccess`,
                    method: "post",
                    data: {
                        plateNo: this.taskDetail.plateNo,
                        frameNo: this.taskDetail.frameNo,
                        mobile: this.taskDetail.ownerPhoneNo
//                         "plateNo":"京AU7Q3K",
//                         "frameNo":"LNNUJBJZ826YLJADX"
                    }
                }).then(res => {
                    if (!isArray(res)) return
                    res.map(item => {
                        item.total = (+item.compulsory.vehicleTaxPremium + +item.compulsory.actualPremium + +item.commercial.actualPremium).toFixed(2)
                    })
                    this.priceRecord = res
                })
            })
            request({
                url: `afis-renewal/renewal/renewalTaskTrackRecord/queryTrackRecordListByTaskId/${id}`,
                method: "get",
                data: {
            }
            }).then(res => {
                this.followRecord = res
            })
        },
        dictFilter (dicts, value) {
            const filterPro = !this.dictMap[dicts]
                ? []
                : this.dictMap[dicts].filter(dict => {
                return dict.value === value
                })
            if (filterPro && filterPro.length >= 1) {
                return filterPro[0].label
            } else return value
        },
        follow () {
            this.$refs.FollowForm.show = true
            this.$refs.FollowForm.init()
        },
        tabClick () {
            this.getDetail(this.id)
        },
        updateList (id) {
            this.getDetail(this.id)
        }
	}
}
</script>
<style lang="scss" scoped>
.follow ::v-deep {
    background: linear-gradient(#00A194, #fff);
    .follow-scroll {
        overflow: auto;
    }
    .back-operate {
        padding-top: 14px;
        height: 24px;
        line-height: 24px;
        padding-left: 10px;
        text-align: center;
        padding-bottom: 4px;
        .van-image {
            position: absolute;
            left: 10px;
        }
        span {
            color: #fff;
            font-size: 17px;
            vertical-align: top;
        }
    }
    .van-nav-bar {
        background: transparent;
        color: #fff;
    }
    .van-nav-bar__title {
        color: #fff !important;
    }

    .van-icon-arrow-left {
        color: #fff;
    }
    .van-icon-checked:before {
        color: #15AEA2;
    }
    .bottom-btn {
        position: fixed;
        bottom: 0;
        height: 60px;
        line-height: 60px;
        padding-left: 64%;
        background: #fff;
        .follow-btn {
            width: 3rem;
            display: inline-block;
            height: 44px;
            position: relative;
            bottom: 16px;
            right: 50px;
        }
    }
    .car-info {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        .car-time {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #E5E5E5;
            padding-bottom: 0.31rem;
            .plat-no {
                font-size: 0.46rem;
            }
        }
        .date-btn {
            width: 100px;
            height: 26px;
            background-color: #fff;
            color: #35B8AD;
            font-size: 12px;
            display: inline-block;
            border-color: #35B8AD;
        }
        .name-time {
            display: flex;
            justify-content: space-between;
            color: #909090;
            font-size: 13px;
            padding-top: 0.2rem;
        }
    }
    .tab-con {
        background: #fff;
        border-radius: 6px;
        padding: 12px;
        .van-tab--active {
            color: #000;
            font-weight: bold;
        }
        .task-detail {
            margin-top: 10px;
            .car-people {
                border-bottom: 1px dashed #37B8AD;
                padding-bottom: 0.45rem;
                p {
                    margin: 0 auto;
                    height: 32px;
                    line-height: 32px;
                }
                .title {
                    display: inline-block;
                    width: 100px;
                    font-size: 13px;
                    color: #8A8989;
                }
                .title-con {
                    font-size: 13px;
                }
            }
        }
        .follow-record {
            .time {
                font-size: 12px;
                color: #8A8989;
            }
            .follow-content {
                font-size: 12px;
                color: #000;
                .title {
                    color: #8A8989;
                    display: inline-block;
                    width: 86px;
                }
                .btn {
                    display: inline-block;
                    width: 110px;
                    height: 19px;
                    font-size: 12px;
                    position: absolute;
                    right: 0px;
                }
            }
            .van-step__title {
                background: #F2F3F4;
                border-radius: 0px 8px 8px 8px;
                padding: 0.3rem 0.2rem 0.1rem 0.3rem;
            }
        }
        .price-record {
            p {
                margin: 0;
                padding: 0;
            }
            .content {
                .title {
                    width: 80px;
                }
                .title-con {
                    position: absolute;
                    right: 16px;
                    color: #000;
                }
            }
            table {
                .list-tr {
                    color: #616161;
                    font-size: 11px;
                    height: 34px;
                    line-height: 34px;
                }
                .th {
                    font-size: 12px;
                    color: #616161;
                }
            }
            .table .list-tr:nth-child(odd) {
                background: #F6F7F7;
            }
            .table .list-tr:nth-child(even) {
                background: #fff;
            }
            .total-price {
                height: 50px;
                line-height: 50px;
                text-align: right;
                .title {
                    font-size: 12px;
                }
                .title-con {
                    font-size: 16px;
                    color: rgb(255, 82, 40);
                }
            }
        }
        .collapse-head {
            .title-con {
                font-size: 16px;
                color: rgb(255, 82, 40);
            }
        }
    }
}
</style>