<template>
  <div class="follow-form">
        <van-action-sheet v-model="show">
            <van-form ref="form" alidate-first @submit="onSubmit">
                <HSelect ref="trackStatus" :config="config.trackStatus" @input="trackStatusInput" />
                <HSelect ref="trackIntent" :config="config.trackIntent" />
                <HSelect ref="lossReason" v-if="config.lossReason.required" :config="config.lossReason" />
                <HDatePicker ref="nextTrackTime" :config="config.nextTrackTime" />
                <van-cell style="position: relative; right: 4px;">
                    <template #title>
                        <span style="color: red; position: relative; right: 5px;" v-if="config.trackDescription.required">*</span>
                        <span style="color: rgb(112, 112, 112); font-size: 14px;">跟进记录</span>
                    </template>
                </van-cell>
                <HInput ref="trackDescription" class="task-text" :config="config.trackDescription" />
                <div class="follow-tag" style="font-size: 12px;">
                    <HTag :list="followTags" :id="id" :maxNum="5" @close="close" @getTagList="getTagList" @updateRecord="updateRecord" />
                </div>
                <div style="margin: 16px; display: flex; justify-content: space-around;">
                    <van-button round block type="info" native-type="button" @click="cancel" style="width: 40%; display: inline-block; background: #fff; color: #15AEA2; border: 1px solid #15AEA2;">取消</van-button>
                    <van-button round block type="info" color="#15AEA2" style="width: 40%; display: inline-block;">确定</van-button>
                </div>
            </van-form>
        </van-action-sheet>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapState } from "vuex";
import { Notify, Toast } from 'vant'
import { request } from "@/api/service";
import HSelect from '@/components/form-items/HSelectAccount.vue'
import HDatePicker from '@/components/form-items/HDatePicker.vue'
import HTag from '@/components/form-items/HTag.vue'
import HInput from '@/components/form-items/HInputAccount.vue'
import store from '@/store'
export default {
	name:'FollowForm',
	components: { HSelect, HDatePicker, HTag, HInput },
    props: {
        id: {
            type: String
        }
    },
    provide () {
		return {
			pageContext: this.pageContext
		}
	},
    created () {
    },
	data() {
		return {
            testRule: [
                        {
                            required: true,
                            message: "跟进状态必填",
                            trigger: "change",
                        }
                    ],
            pageContext: this,
            trackStatusShow: false,
            nextTrackTimeShow: false,
            trackIntentShow: false,
            lossReasonShow: false,
            repairTypeShow: false,
            show: false,
            form: {
                trackStatus: '',
                trackStatusName: '',
                nextTrackTime: '',
                nextTrackTimeStr: '',
                trackIntent: '',
                trackIntentName: '',
                lossReason: '',
                lossReasonName: '',
                trackDescription: '',
                repairType: '',
                repairTypeName: ''
            },
            columns: [],
            config: {
                trackStatus: {
                    val: '1',
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '跟进状态',
                    required: true,
                    dictCode: 'TRACK_STATUS',
                    rules: [
                        {
                            required: true,
                            message: "跟进状态必填",
                            trigger: "change",
                        }
                    ]
                },
                trackIntent: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '客户意向',
                    required: true,
                    dictCode: 'TRACK_INTENT',
                    rules: [
                        {
                            required: true,
                            message: "客户意向必填",
                            trigger: "change",
                        }
                    ]
                },
                lossReason: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '流失原因',
                    required: false,
                    dictCode: 'LOSS_REASON',
                    rules: [
                        {
                            required: true,
                            message: "流失原因必填",
                            trigger: "change",
                        }
                    ]
                },
                nextTrackTime: {
                    val: null,
                    show: true,
                    readonly: true,
                    disabled: false,
                    options: [],
                    valueKey:'value',
                    labelKey:'label',
                    clearable: true,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请选择',
                    label: '下次跟进日期',
                    required: true,
                    dictCode: 'LOSS_REASON',
                    minDate: new Date(),
                    rules: [
                        {
                            required: true,
                            message: "下次跟进日期必填",
                            trigger: "change",
                        }
                    ]
                },
                trackDescription: {
                    val: '',
                    text: '',
                    show: true,
                    readonly: false,
                    disabled: false,
                    blurEvent: null,
                    changeEvent: null,
                    placeholder: '请输入',
                    label: '',
                    required: true,
                    dictCode: null,
                    type: 'textarea',
                    rules: [
                        {
                            required: true,
                            message: "跟进记录必填",
                            trigger: "change",
                        }
                    ]
                }
            },
            followTags: []
		}
	},
	computed: {
        ...mapState("dict", ["dictMap"])
    },
	created(){
        store.dispatch('dict/loadDict')
	},
	mounted () {
    },
	methods:{
		dayjs,
        statusCli () {
            this.trackStatusShow = true
        },
        onSubmit () {
            request({
                url: `/afis-renewal/renewal/renewalTaskTrackRecord/save`,
                method: "post",
                data: {
                    taskId: this.id,
                    trackStatus: this.config.trackStatus.val,
                    nextTrackTime: this.config.nextTrackTime.val,
                    trackIntent: this.config.trackIntent.val,
                    lossReason: this.config.lossReason.val,
                    trackDescription: this.config.trackDescription.val
                }
            }).then(res => {
                Toast.success('跟进成功')
                this.$emit('updateList', this.id)
                this.show = false
            })
        },
        init () {
            for (let i in this.config) {
                this.config[i].val = null
                this.config[i].required = true
            }
            this.config.trackStatus.val = '1'
            this.config.lossReason.required = false
            this.config.trackDescription.required = false
            this.config.trackDescription.rules[0].required = false
            this.getTagList()
            this.$refs.form && this.$refs.form.resetValidation()
        },
        trackStatusInput (val) {
            this.$refs.form.resetValidation()
            if (val === '1') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['trackIntent', 'nextTrackTime', 'trackStatus'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            } else if (val === '2') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['trackIntent', 'trackDescription', 'trackStatus'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            } else if (val === '4') {
                for (let i in this.config) {
                    let rules = this.config[i].rules
                    if (['lossReason', 'trackStatus'].includes(i)) {
                        this.config[i].required = true
                        rules[0].required = true
                    } else {
                        this.config[i].required = false
                        rules[0].required = false
                    }
                    this.$set(this.config[i], 'rules', rules)
                }
            }
        },
        close () {
           this.getTagList()
        },
        getTagList () {
            this.$refs.form && this.$refs.form.resetValidation()
            request({
                url: `/afis-engine/label/cfgLabel/listByUse`,
                method: "post",
                data: {
                }
            }).then(res => {
                this.followTags = res
            })
        },
        updateRecord (labelName) {
            this.config.trackDescription.val += labelName
            this.config.trackDescription.val = this.config.trackDescription.val.replace('null', '')
        },
        cancel () {
            this.show = false
        }
	}
}
</script>
<style lang="scss" scoped>
.follow-form ::v-deep {
    .van-field__body input {
        text-align: right;
    }
    .van-field__label {
    }
    .task-text::before {
        display: none;
    }
    .task-text {
        padding-top: 0px;
        .van-cell__value {
            padding: 10px;
            background: #F2F2F6;
        }
    }
    .follow-tag {
        padding-left: 16px;
    }
    .van-field--error .van-field__control, .van-field--error .van-field__control::placeholder {
        color: #CDCDDB;
    }
}
</style>